/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Link } from 'gatsby';

import paths from '../utils/paths';
import useCurrentLanguage from '../hooks/use-current-language';

import Layout from '../components/Layout';
import Container from '../components/Container2';

const NotFoundPage = () => {
  const lang = useCurrentLanguage();
  return (
    <Layout
      page="404"
      seo={{
        title: '404',
      }}
    >
      <Container>
        <Styled.h1>Page not found</Styled.h1>
        <Styled.p sx={{ mt: '48px', color: 'muted' }}>
          Oops! The page you are looking for has been removed or relocated.
        </Styled.p>
        <Styled.p sx={{ mt: '32px' }}>
          <Link to={paths.home[lang]} sx={{ color: 'inherit' }}>
            Go back
          </Link>
        </Styled.p>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
